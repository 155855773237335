.App {
  text-align: center;
  padding-top: 60px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner {
  width: 100%;
  height: 60px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.banner-content {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 100%;
}

.banner-logo {
  height: 40px;
  width: 40px;
  object-fit: contain;
  animation: none;
}

.banner-text {
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  font-style: italic;
}

.banner-text-container {
  flex-direction: column;
  margin-left: 10px;
}

.banner-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  font-style: italic;
}

.banner-subtitle {
  font-size: 0.9rem;
  color: #666;
  margin-left: 10px;
  font-style: italic;
}

.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1000px;
  width: 100%;
}

.pricing-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card.pro {
  border: 2px solid #007bff;
  position: relative;
}

.pricing-header {
  margin-bottom: 2rem;
}

.pricing-header h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.price {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
  margin: 1rem 0;
}

.price span {
  font-size: 1rem;
  color: #666;
}

.features {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  text-align: left;
  flex-grow: 1;
}

.features li {
  display: flex;
  align-items: flex-start;
  margin: 1.2rem 0;
  padding: 0.5rem 0;
}

.feature-icon {
  color: #007bff;
  margin-right: 1rem;
  font-size: 1.2rem;
  min-width: 24px;
}

.feature-text {
  display: flex;
  flex-direction: column;
}

.feature-text strong {
  color: #333;
  margin-bottom: 0.2rem;
}

.feature-text p {
  color: #666;
  margin: 0;
  font-size: 0.9rem;
}

.plan-button {
  padding: 1rem 2rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease;
}

.current-plan {
  color: #495057;
  border: none;
  cursor: default;
}

.upgrade-button {
  background-color: #007bff;
  color: white;
  border: none;
}

.upgrade-button:hover {
  background-color: #0056b3;
}

.upgrade-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  padding: 20px;
  background: #f8f9fa;
}

.success-card {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.success-icon {
  font-size: 48px;
  color: #28a745;
  margin-bottom: 20px;
}

.plan-details {
  margin: 30px 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.plan-details ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.plan-details li {
  margin: 10px 0;
  color: #666;
}

.return-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.return-button:hover {
  background: #0056b3;
}

.pricing-card.current-plan {
  border: 2px solid #4CAF50;
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.1);
}

.current-plan-tag {
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-bottom: 8px;
  display: inline-block;
}